@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.animate {
  animation: FadeAnimation 0.5s ease-in 5s forwards;
}
