.input-group {
  padding-bottom: 1.5rem;
}

.invalid-feedback {
  position: absolute;
  bottom: 0;
}

.form-control:disabled {
  color: #777;
}

.form-disabled {
  opacity: 0.5;
}

.hide-select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;

  /* For IE10 */
  &::-ms-expand {
    display: none;
  }
}

.file-plaintext {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
}

// Add missing style for form switch on Safari
.form-check-input {
  -webkit-appearance: none;
}
