// From https://christianoliff.com/blog/bootstrap-5-print-stylesheet/

@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;

    &::before,
    &::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  pre {
    white-space: pre-wrap !important;
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body,
  .container {
    min-width: 992px !important;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: #fff !important;
    }
  }

  .table-bordered {
    th,
    td {
      border: 1px solid #dee2e6 !important;
    }
  }

  .table-dark {
    color: inherit;

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: #dee2e6;
    }
  }

  .search-input {
    width: 30%;
  }

  .nav__margin .fixed-top {
    position: static;
  }
}
