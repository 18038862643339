.result-list {
  &__header-row {
    font-family: $ff-OswaldBold;
    font-size: 1.25rem;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  &__row {
    padding: 10px 0;
    &:nth-child(even) {
      background: #f2f2f2;
    }
    &:last-child {
      border-bottom: 1px solid #ccc;
    }
    &-link {
      color: #212529;
      text-decoration: none;
    }
  }

  &__receipt {
    min-width: 105px;
    font-size: 1.25rem;
    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
  }

  &__name {
    font-size: 1.25rem;
    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
  }

  &__void {
    min-width: 75px;
  }

  &__total {
    min-width: 150px;
  }

  &__date {
    min-width: 110px;
  }
}
