.profilecard {
  &__header {
    background: none;
    &-wrapper {
      display: flex;
      align-items: center;
      gap: 15px;
      width: 100%;
    }
    &-icon {
      font-size: 25px;
      color: $c-riverbed-grey;
    }
  }
  &__body {
    @include media-breakpoint-up(md) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
  &__listgroup {
    &-item {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      align-items: flex-start;
      gap: 15px;
      @include media-breakpoint-up(md) {
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    &-icon {
      color: $c-riverbed-grey;
      font-size: 18px;
      margin-top: 3px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
        font-size: 20px;
      }
    }
  }

  &__btn {
    display: block;
    width: 100%;
    border-radius: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    &--edit {
      width: auto;
      padding: 7px 12px 5px;
    }
    &-icon {
      font-size: 20px;
      margin-right: 15px;
    }
  }
}
