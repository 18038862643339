.nav {
  &__bar {
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 0;
    padding-bottom: 0;
  }
  &__bb {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid #fff;
  }
  &__item {
    background: rgba(255, 255, 255, 0.05);

    &--logout {
      margin-top: auto;
      background: $c-blackpearl-blue;
    }
  }
  &__logo {
    width: 200px;
  }
  &__icon {
    margin-right: 15px;
  }
  &__margin {
    margin-top: 62px;
    padding-top: 20px;
  }
  &__toggler {
    border: 0;
    padding: 0;
    position: relative;
    width: 55px;
    height: 45px;
    transition: 0.5s ease-in-out;
    .navbar-light & {
      &:focus {
        outline: 5px auto Highlight;
        outline: 5px auto -webkit-focus-ring-color;
        box-shadow: none;
      }
    }
    span {
      position: absolute;
      height: 4px;
      width: 100%;
      vertical-align: middle;
      margin-top: 10px;
      background: $c-cod-grey;
      border-radius: 8px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 10px;
      }
      &:nth-child(4) {
        top: 20px;
      }
    }
    &.isopen {
      span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
        opacity: 0;
      }
      span:nth-child(2) {
        transform: rotate(45deg);
      }
      span:nth-child(3) {
        transform: rotate(-45deg);
      }
      span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
        opacity: 0;
      }
    }
  }
  &__collapse {
    display: flex;
    align-items: stretch;
  }
  &__nav {
    flex-grow: 1;
    height: calc(100vh - 48px);
    background: $c-bright-grey;
  }
  &__link {
    .navbar-light .navbar-nav & {
      color: #fff;
      text-align: center;
      letter-spacing: 1px;
      padding-top: 22px;
      padding-bottom: 22px;
      font-family: $ff-OswaldReg;
      &:hover,
      &:focus {
        color: #fff;
        background: $c-shiraz-red;
      }
    }
  }
}
