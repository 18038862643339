// Roboto Medium
@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto'),
    url('./../../Fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto'),
    url('./../../Fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

// Oswald
@font-face {
  font-family: 'Oswald-Regular';
  src: local('Oswald'),
    url('./../../Fonts/Oswald/Oswald-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Oswald-Bold';
  src: local('Oswald'),
    url('./../../Fonts/Oswald/Oswald-Bold.ttf') format('truetype');
  font-weight: 700;
}
