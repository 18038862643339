.word-break-all {
  word-break: break-all;
}

.ft {
  &-header {
    font-family: $ff-OswaldReg;
  }

  &-header-bold {
    font-family: $ff-OswaldBold;
  }

  &-body {
    font-family: $ff-RobotoMed;
  }

  &-body-bold {
    font-family: $ff-RobotoBold;
  }
}

.btn {
  &__border {
    &--grey {
      // currently used for the search field reset button
      border: 1px solid $c-ghost-grey;
    }
  }
}

.mxw {
  &-100 {
    max-width: 100px;
  }
  &-150 {
    max-width: 150px;
  }
  &-200 {
    max-width: 200px;
  }
  &-400 {
    max-width: 400px;
  }
}

.mnh {
  &-60 {
    min-height: 60px;
  }
}

.w {
  &-20 {
    width: 20px;
  }
}
