.CalendarDay.CalendarDay__today {
  background: $primary;
  color: white;
}

.full-width-datepicker {
  .DateRangePicker {
    width: calc(100% - 35px);
    .DateRangePickerInput {
      width: 100%;
    }
  }
}

.DateRangePicker .DateRangePickerInput__withBorder {
  border-right-color: transparent;
}
