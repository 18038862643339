.void-stamp {
  color: $primary;
  border: 4px solid;
  background: rgba($primary, 0.05);
  padding: 5px 10px;
  font-size: 1.7rem;
  border-radius: 5px;
  font-weight: bold;
  font-family: Times;
  letter-spacing: 3px;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 50%);
  transform: rotate(12deg);
  margin-left: auto;
  position: absolute;
  right: -10px;

  &--row {
    display: inline-block;
    top: 50%;
    transform: translate(-20%, -50%) rotate(12deg);
    @include media-breakpoint-up(lg) {
      font-size: 1rem;
      letter-spacing: 0;
      position: static;
      transform: none;
      padding: 8px 5px;
    }
  }

  &--print {
    display: inline-block;
    position: static;
    transform: none;
    font-size: 1rem;
  }
}
