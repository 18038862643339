// Colours
$c-shiraz-red: #b10f3c;
$c-bunting-blue: #1a2550;

$c-athens-grey: #F2F3F5;
$c-ghost-grey: #ced4da;
$c-riverbed-grey: #4d5568;
$c-blackpearl-blue: #09112e;
$c-bright-grey: #3a404e;
$c-cod-grey: #171717;

// Fonts
$ff-RobotoMed: 'Roboto-Medium', 'Helvetica Neue', Arial, sans-serif;
$ff-RobotoBold: 'Roboto-Bold', 'Helvetica Neue', Arial, sans-serif;

$ff-OswaldReg: 'Oswald-Regular', 'Helvetica Neue', Arial, sans-serif;
$ff-OswaldBold: 'Oswald-Bold', 'Helvetica Neue', Arial, sans-serif;
