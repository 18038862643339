.thumbnail {
  padding: 3px;
  border: 1px solid transparent;
  transition: 0.2s border;
  max-width: 100px;

  &:hover,
  &:active {
    border: 1px solid #ccc;
    cursor: pointer;
  }
}

// Hide lightbox rotate buttons on mobile
.lb-icon-rotate {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}
